<template>
    <section class="white p-3 border-radius-15 d-block d-md-none mb-3">
        <section>
            <div class="backgrund-image-placement w-100 border-radius-10 mb-4"
                 v-bind:style="{'background-image': `url(${img})`}"
                 style="aspect-ratio: 4 / 3">
            </div>
        </section>
        <slot/>
    </section>

    <section class="d-none d-md-block">
        <section class="d-flex mb-5">
            <section v-if="position === 'left' || position === undefined"
                     class="w-50 pt-4 pb-4">
                <div class="white position-relative p-5 border-radius-15 shadow"
                     style="margin-right: -40px; z-index: 1">
                    <slot/>
                </div>
            </section>

            <section class="w-50">
                <div class="backgrund-image-placement h-100 w-100 border-radius-15"
                     v-bind:style="{'background-image': `url(${img})`}"
                     style="height: 100%; overflow: hidden">
                </div>
            </section>

            <section v-if="position === 'right'" class="w-50 pt-4 pb-4">
                <div class="white position-relative p-5 border-radius-15 shadow"
                     style="margin-left: -40px; z-index: 1">
                    <slot/>
                </div>
            </section>
        </section>
    </section>
</template>

<script>
    // @ is an alias to /src

    export default {
        name: 'BoxPhotoComponent',
        props: ['position', 'img'],
    }
</script>

<style scoped>

    .carousel__icon {
        color: white;
    }

    .carousel__pagination-button:hover::after {
        background-color: #0D47A1 !important;
    }

    .carousel__pagination-button--active::after {
        background-color: #0D47A1 !important;
    }

</style>
