<template>
    <div>
        <div class="font-700 font-size-30 mb-3 text-gradient-green">
            Oferta Educativa
        </div>

        <div class="d-flex overflow-auto pb-3" id="containter-ofertas">
            <div v-for="(oferta, index) in i18n.oferta_educativa"
                 :key="index"
                 class="white border-radius-5 flex-shrink-0 flex-grow-0 me-3"
                 style=" width: 400px; overflow: hidden;">
                <div class="p-3 position-relative h-100">
                    <div class="font-size-22 font-700 text-color-600">
                        {{oferta.nombre}}
                    </div>
                    <hr/>
                    <ul style="margin-left: -13px;" class="mb-4">
                        <li v-for="(p, indexP) in oferta.puntos" :key="indexP">{{p}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src

    import 'vue3-carousel/dist/carousel.css'

    export default {
        name: 'SliderOferta',

        components: {},

        props: ['images'],

        data() {
            return {
                startX: 0,
                scrollLeft: null,
                ele: null,
                mouseDown: false
            }
        },

        methods: {
            startDragging(e) {
                this.mouseDown = true;
                this.startX = e.pageX;
                this.scrollLeft = this.ele.scrollLeft;
            },
            drag(e) {
                if (this.mouseDown) {
                    e.preventDefault();
                    const x = e.pageX;
                    const walk = (x - this.startX) * 2; // Adjust scroll speed here
                    this.ele.scrollLeft = this.scrollLeft - walk;
                }
            },
            stopDragging() {
                this.mouseDown = false;
            }
        },

        mounted() {
            this.ele = document.getElementById('containter-ofertas');

            this.ele.addEventListener('mousedown', this.startDragging, false);
            this.ele.addEventListener('mousemove', this.drag, false);
            this.ele.addEventListener('mouseup', this.stopDragging, false);
            this.ele.addEventListener('mouseleave', this.stopDragging, false);
        }

    }
</script>

<style scoped>

    .carousel__icon{
        color: white;
    }

    .carousel__pagination-button:hover::after{
        background-color: #0D47A1 !important;
    }

    .carousel__pagination-button--active::after{
        background-color: #0D47A1 !important;
    }

</style>
