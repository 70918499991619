<template>
    <svg xmlns="http://www.w3.org/2000/svg"
         class="icon icon-tabler icon-tabler-basket"
         :width="size"
         :height="size"
         :stroke-width="stroke"
         viewBox="0 0 24 24"
         stroke="currentColor"
         fill="none" stroke-linecap="round" stroke-linejoin="round">
        <slot></slot>
    </svg>
</template>

<script>
    export default {
        props: ['size', 'stroke'],
        name: 'BaseIcon',
    }
</script>

<style scoped>
    svg{
        margin-top: -2px;
    }
</style>