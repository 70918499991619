<template>
    <div class="main-color-text">
        <div class="row">
            <section class="col-lg-6 mb-3">
                <h5 class="font-600 font-size-22">Noticias</h5>
                <hr/>
                <div>
                    <p>
                        Se han publicdo las fechas de matrícula de las pruebas de acceso de grado superior y grado
                        medio.
                    </p>
                    <p>
                        Os adjuntamos los links para más información:
                    </p>
                    <p>
                        <a href="htts://www.caib.es/sacmicrofront/contenido.do?mkey=M14&lang=CA&cont=64200"
                           class="main-color-text font-600">
                            Pruebas de Acceso a Grado Medio
                        </a>
                    </p>
                    <p>
                        <a href="https://www.caib.es/sacmicrofront/contenido.do?mkey=M14&lang=CA&cont=64217"
                           class="main-color-text font-600">
                            Pruebas de Acceso a Grado Superior
                        </a>
                    </p>
                </div>
            </section>
            <section class="col-lg-6 mb-3">
                <h5 class="font-600 font-size-22">Enlaces</h5>
                <hr/>
                <div>
                    <p>
                        1. Tota la informació del cicles formatius de grau mitjà i superior (temaris, dates de les
                        proves d'acces, places disponibles...)
                        <br/>
                        <a href="https://www.caib.es/" class="main-color-text font-600">Aquí</a>
                    </p>

                    <p>
                        2. Examens de selectivitat de les proves anteriors
                        <br/>
                        <a href="https://estudis.uib.cat" class="main-color-text font-600">Aquí</a>
                    </p>

                    <p>
                        3. exàmens proves d'accés a universitat per a majors de 25 anys.
                        <br/>
                        <a href="https://estudis.uib.cat" class="main-color-text font-600">Aquí</a>
                    </p>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'NoticiasPage',
    }
</script>
