<template>
    <footer class="main-color white-text">
        <div class="p-3">
            <div class="container pt-3 pb-3">
                <div class="row">
                    <div class="col-lg-3 mb-5 mb-md-0">
                        <div class="ps-3 " style="border-left: 2px solid white">
                            <div class="mb-2">
                                <a href="documents/aviso_legal.pdf"
                                   class="white-text text-decoration-none">
                                    {{i18n.buttons.aviso_legal}}
                                </a>
                            </div>

                            <div class="mb-2">
                                <a href="documents/politica_cookies.pdf"
                                   class="white-text text-decoration-none">
                                    {{i18n.buttons.politica_cookies}}
                                </a>
                            </div>

                            <div class="mb-2">
                                <a href="documents/politica_privacidad.pdf"
                                   class="white-text text-decoration-none">
                                    {{i18n.buttons.privacidad}}
                                </a>
                            </div>

                            <a href="documents/clausula_apartado_contacto.pdf"
                               class="white-text text-decoration-none">
                                {{i18n.buttons.contacto}}
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-3 mb-5 mb-md-0" v-html="i18n.footer.content"></div>
                    <div class="col-lg-3 offset-lg-3">
                        <div class="d-flex mb-2">
                            <a href="https://www.facebook.com/Cemveri" class="white-text me-1">
                                <IconBrandFacebook stroke=1.2 size=25 />
                            </a>
                        </div>
                        <div class="font-size-14" style="line-height: 25px">
                            Maó <span class="font-600">971 351 200</span>
                            <br/>
                            info@cemveri.com
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="main-color text-center white-text">
            <div class="p-3" style="height: 100%; width: 100%; background-color: rgba(0,0,0,0.1)"></div>
        </div>
    </footer>

</template>

<script>
    import IconBrandFacebook from "@/components/icons/IconBrandFacebook";

    export default {
        props: ['auth'],
        name: 'FooterHome',
        components: {IconBrandFacebook}
    }
</script>
