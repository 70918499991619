import {createApp} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import './scss/app.scss'
import Vuex from 'vuex'
import {getI18n} from "@/locales/locales";

const app = createApp(App).use(router, Vuex)

app.config.globalProperties.i18n = getI18n();

app.mount('#app');

