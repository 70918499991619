<template>
    <BaseIcon :size="size" :stroke="stroke">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M10 10l4 4m0 -4l-4 4"></path>
        <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z"></path>
    </BaseIcon>
</template>

<script>
    import BaseIcon from "@/components/icons/BaseIcon";

    export default {
        components: {BaseIcon},
        props: ['size', 'stroke'],
        name: 'IconClose',
    }
</script>