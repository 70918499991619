<template>
    <BaseIcon :size="size" :stroke="stroke">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <path d="M15 10l-4 4l6 6l4 -16l-18 7l4 2l2 6l3 -4"/>
    </BaseIcon>
</template>

<script>

    import BaseIcon from "@/components/icons/BaseIcon";

    export default {
        components: {BaseIcon},
        props: ['size', 'stroke'],
        name: 'IconSend',
    }
</script>