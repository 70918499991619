<template>

  <section>
    <div class="font-700 font-size-30 mb-3 text-gradient-green">
      Contacto
    </div>
    <div class="mb-3">
      <label>Nombre</label>
      <input v-model="form.nombre" type="text" class="white input">
    </div>

    <div class="mb-3">
      <label>Email</label>
      <input v-model="form.email"
             type="text"
             :class="{'email-invalid': (!isEmailValid && this.form.email !== '')}"
             class="white input">
    </div>

    <div class="mb-3">
      <label>Telefono</label>
      <input v-model="form.telefono" type="text" class="white input">
    </div>

    <div class="mb-3">
      <label>Consulta</label>
      <textarea v-model="form.consulta" type="text" class="white input" style="height: 150px"></textarea>
    </div>

    <div class="mb-3">
      <div class="form-group form-check">
        <input v-model="acepto_politica"
               type="checkbox"
               class="form-check-input"
               id="exampleCheck1">
        <label class="form-check-label"
               for="exampleCheck1">
          <a class="main-color-text font-600"
             href="documents/politica_privacidad.pdf">
            {{i18n.form_contact.politica_de_privacidad}}
          </a>
        </label>
      </div>
    </div>

    <div>
      <button v-if="sendStatus === 0"
              @click="recaptcha"
              :disabled="!cansend"
              class="button button-sm white font-600 main-color-text">
        <IconSend size=16 stroke=2 class="me-1"/>
        Enviar
      </button>

      <button v-else-if="sendStatus === 1"
              class="button button-sm white font-600 main-color-text">
        <IconLoading size=16 stroke=2 class="me-1 icon-rotate"/>
        Enviando
      </button>

      <button v-else-if="sendStatus === 2"
              class="button button-sm white font-600 main-color-text">
        <IconSend size=16 stroke=2 class="me-1"/>
        Enviado!
      </button>

      <button v-else-if="sendStatus === 3"
              class="button button-sm red lighten-4 red-text text-darken-2 font-600">
        <IconSend size=16 stroke=2 class="me-1"/>
        Error!
      </button>
    </div>
  </section>

</template>

<script>

import {load} from 'recaptcha-v3'
import IconSend from "@/components/icons/IconSend";
import IconLoading from "@/components/icons/IconLoading";
import axios from 'axios';

export default {
  name: 'ContactSection',

  components: {IconLoading, IconSend},

  data() {
    return {
      sendStatus: 0,
      acepto_politica: false,
      form: {
        nombre: null,
        email: '',
        telefono: null,
        consulta: null,
      }
    };
  },

  computed: {
    cansend() {
      if (this.form.nombre === null || this.form.nombre === '') return false;
      if (this.form.email === null || this.form.email === '') return false;
      if (this.form.telefono === null || this.form.telefono === '') return false;
      if (this.form.consulta === null || this.form.consulta === '') return false;
      if (!this.acepto_politica) return false;
      if (!this.isEmailValid) return false;

      return true;
    },
    isEmailValid(){
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(this.form.email);
    }
  },

  methods: {
    async recaptcha() {
      this.sendStatus = 1;
      const recaptcha = await load('6Ldc6e4qAAAAAE-AagUarJOmRtXsyNSNAAM1sAiy');
      await recaptcha.execute(this.sendForm());
    },
    async sendForm() {
      axios.post('https://api.lunikhub.com/api/mail-sender/send', this.form).then(() => {
        this.sendStatus = 2;
      }).catch(() => {
        this.sendStatus = 3;
      });
    }
  }
}

</script>

<style scoped>

button:disabled {
  opacity: 0.5;
}

.email-invalid{
  border: 2px #f44336 solid;
}

</style>