<template>
    <div>
        <Navbar />
    </div>
    <div class="color-50" style="padding-top: 100px; min-height: 100vh">
        <div class="container pt-3 pb-3">
            <slot></slot>
        </div>
    </div>
</template>

<script>

    import Navbar from "@/partials/Navbar";

    export default {
        components: {
            Navbar
        },

        data() {
            return {
                checkAuth: false
            };
        },

        mounted() {}
    }
</script>
