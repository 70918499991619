<template>
    <div class="main-color-text">
        <BoxPhotoComponent
                position="left"
                img="/img/foto_1.webp">

            <h1 class="font-size-38 font-800 text-gradient-green mb-1" v-html="i18n.home.h1"></h1>
            <h2 class="font-size-30 font-800 text-gradient-green mb-3" v-html="i18n.home.h2"></h2>
            <h3 class="font-size-20 font-600 mb-5" v-html="i18n.home.h3"></h3>
            <div class="font-size-14" v-html="i18n.home.content"></div>
        </BoxPhotoComponent>

        <BoxPhotoComponent
                position="right"
                img="/img/foto_2.webp">

            <div class="font-700 font-size-30 mb-3 text-gradient-green" v-html="i18n.home2.h1"></div>
            <div class="font-size-14" v-html="i18n.home2.content"></div>
        </BoxPhotoComponent>

        <section class="mb-5">
            <SliderOferta />
        </section>

        <div class="row">
            <div class="col-lg-6">
                <CentrosSection />
            </div>
            <div class="col-lg-6">
                <ContactSection />
            </div>
        </div>
    </div>
</template>

<script>

    import SliderOferta from "@/components/SliderOferta";
    import BoxPhotoComponent from "@/components/BoxPhotoComponent";
    import ContactSection from "@/views/sections/ContactSection";
    import CentrosSection from "@/views/sections/CentrosSection";

    export default {
        name: 'HomeView',
        components: {CentrosSection, ContactSection,  BoxPhotoComponent, SliderOferta},
    }

</script>

<style scoped>

    .card-footer a {
        text-decoration: none !important;
    }

</style>