<template>

    <!-- Mobile Nav -->
    <div class="white p-3"
         id="sideNav"
         style="position: fixed; height: 100vh; width: 300px; border-radius: 0px 20px 20px 0px; z-index: 999">

        <div class="mb-4 float-end main-color-text">
            <IconClose size=20 stroke=2 @click="toggleNavigation" />
        </div>

        <div class="clearfix">
            <div style="width: fit-content; margin: auto" class="mb-4">
                <router-link to="/"
                             class="text-decoration-none color-text font-size-30 font-400 text-uppercase text-gradient">
                    <img
                        style="height: 70px"
                        src="/img/logo_principal.webp"
                    />
                </router-link>
            </div>
            <div class="mb-2">
                <a href="/"
                   @click="setLocale('es')"
                   class="main-color-text me-3 text-decoration-none">
                    CASTELLANO
                </a>
            </div>
            <div class="mb-2">
                <a href="/"
                   @click="setLocale('ca')"
                   class="main-color-text me-1 text-decoration-none">
                    CATALÀ
                </a>
            </div>
        </div>
    </div>

    <!-- Desktop nav -->
    <div class="teal-text color-text p-3 font-size-16 font-500 position-fixed w-100"
         style="top: 0px; z-index: 10; backdrop-filter: blur(7px); background: rgba(255, 255, 255, .7)">
        <div class="container clearfix" style="line-height: 45px">
            <router-link to="/"
                         class="float-start text-decoration-none color-azul-text font-size-30 font-400 text-uppercase text-gradient">
                <img
                    style="height: 70px"
                    src="/img/logo_principal.webp"
                />
            </router-link>

            <div class="float-end main-color-text">
                <!-- Menu Desktop -->
                <div class="d-none d-md-block">
                    <div class="clearfix"
                         style="line-height: 70px">
                        <div class="font-300 float-end">
                            <a href="/" @click="setLocale('es')" class="main-color-text me-3 text-decoration-none">
                                CASTELLANO
                            </a>

                            <a href="/" @click="setLocale('ca')" class="main-color-text me-1 text-decoration-none">
                                CATALÀ
                            </a>
                        </div>
                    </div>
                </div>

                <div class="d-block d-md-none float-end" style="line-height: 70px">
                    <IconMenu2 @click="toggleNavigation" class="cursor-pointer"/>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

    import {IconMenu2} from '@tabler/icons-vue';
    import {toggleNav} from "@/functions";
    import IconClose from "@/components/icons/IconClose";

    export default {

        props: ['auth'],

        name: 'NavBar',

        components: {
            IconClose,
            IconMenu2,
        },

        methods: {
            setLocale(locale) {
                localStorage.locale = locale;
            },
            toggleNavigation() {
                toggleNav();
            }
        },

        mounted() {

        }
    }
</script>
