<template>
    <BaseIcon :size="size" :stroke="stroke">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"/>
    </BaseIcon>
</template>

<script>

    import BaseIcon from "@/components/icons/BaseIcon";

    export default {
        components: {BaseIcon},
        props: ['size', 'stroke'],
        name: 'IconBrandFacebook',
    }
</script>