<template>
    <BaseIcon :size="size" :stroke="stroke">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M12 3a9 9 0 1 0 9 9"></path>
    </BaseIcon>
</template>

<script>

    import BaseIcon from "@/components/icons/BaseIcon";
    export default {
        components: {BaseIcon},
        props: ['size', 'stroke'],
        name: 'IconLoading',
    }
</script>