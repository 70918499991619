<template>

    <section class="mb-5">
        <div class="font-700 font-size-30 mb-3 text-gradient-green">
            {{i18n.centros_title}}
        </div>

        <div class="border-radius-10 overflow-hidden backgrund-image-placement h-100"
             :style="{'background-image': `url('${require('@/assets/img/centro_mahon.jpg')}')`}">
            <div class="h-100 w-100 gradient-back p-4 white-text position-relative">
                <div class="position-absolute" style="top: 20px; right: 20px">
                    <a :href="i18n.centros.mao.link_maps"
                       target="_blank"
                       class="btn btn-sm white main-color-text font-600">
                        <IconMapPin stroke=2 size=16 class="me-1"/>
                        Como Llegar
                    </a>
                </div>

                <h5 class="font-700 font-size-24" style="letter-spacing: 1px">
                    {{i18n.centros.mao.nombre}}
                </h5>

                <div class="mb-5">
                    <span class="font-600 font-size-16">Horario</span>
                    <div class="font-300" v-html="i18n.centros.mao.horario"></div>
                </div>

                <div class="w-100 mb-3" v-html="i18n.centros.mao.content"></div>
            </div>
        </div>

    </section>

</template>

<script>

    import IconMapPin from "@/components/icons/IconMapPin";

    export default {
        name: 'CentrosSection',
        components: {IconMapPin},
    }

</script>

<style>

    .gradient-back {
        background: rgb(31, 89, 75);
        background: linear-gradient(94deg, rgba(31, 89, 75, 0.95) 0%, rgba(31, 89, 75, 0.3) 100%);
    }

</style>
