<template>
    <div class="h-100">
        <WebLayout v-if="layout === 'web-layout' || $route.meta.layout === undefined">
            <router-view/>
        </WebLayout>
    </div>
    <FooterHome />
</template>

<style lang="scss" rel="stylesheet/scss"></style>

<script>
    import WebLayout from "@/views/layouts/WebLayout";
    import FooterHome from "@/partials/FooterHome";

    export default {
        components: {
            FooterHome,
            WebLayout,
        },

        computed: {
            layout(){
                return this.$route.meta.layout;
            }
        },

        data() {
            return {
                checkAuth: false
            };
        },

        mounted() {}
    }
</script>
